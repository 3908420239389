<template>
  <div>
    <Pipeline
      :name="pipelineName"
      :title="pipelineTitle"
      :resTable="resTable"
      :filteredResTable="filteredResTable"
      :headers="headers"
      :pipelineId="pipelineId"
    >
    </Pipeline>
  </div>
</template>

<script>
import Pipeline from "../components/Pipeline";
import resTable from "../assets/pipeline1_res_table_210907.json";
import filteredResTable from "../assets/pipeline1_filtered_res_table_210907.json";
import headers from "../assets/pipeline1_colnames_210914.json"
import _ from "lodash"

export default {
  name: "Pipeline1",

  components: {
    Pipeline,
  },

  data() {
    return {
      pipelineId: 0,
      pipelineName: "Pipeline 1",
      pipelineTitle:
        "eQTL indentification based on SVA corrected and TMM normalized Gene expression values with plots from non-modified Gene expression values",
      resTable: [],
      filteredResTable: [],
      headers: [],
    };
  },

  created() {
    function prepareFields(field, i) {
      field.id = i

      field.Plots = {
        pipelineId: 0,
        id: i,
        value: field.gene_associated.value,
        boxplot: {
          fileName: field.box_plot.link.replace(".html", ".json"),
          title: field.box_plot.value,
        },
        manhattan: {
          fileName: field.manhattan_plot.link.replace(".html", ".json"),
          title: field.manhattan_plot.value,
        },
        volcano: {
          fileName: field.volcano_plot.link.replace(".html", ".json"),
          title: field.volcano_plot.value,
        },
      };
      return field;
    }

    this.resTable = _.map(resTable, prepareFields);
    this.filteredResTable = _.map(filteredResTable, prepareFields);
    this.headers = headers.filter((h) => {
      return ! ["box_plot", "manhattan_plot", "volcano_plot"].includes(h.value)
    })

  },
};
</script>
